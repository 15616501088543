import { useState, useEffect } from 'react';
import { useWindowContext } from '../context/WindowContext';
import Draggable from 'react-draggable';
import { XMarkIcon, MinusIcon } from '@heroicons/react/24/solid';
import ReactMarkdown from 'react-markdown';

const Whitepaper = () => {
  const { toggleWhitepaper, isWhitepaperVisible, whitepaperRef } = useWindowContext();


  const [displayedContent, setDisplayedContent] = useState(''); // Content being typed
  const [typingIndex, setTypingIndex] = useState(0); // Tracks the typing progress
  const [markdownContent, setMarkdownContent] = useState(''); // State to store Markdown content

  useEffect(() => {
    // Fetch the Markdown file
    fetch('../whitepaper.md')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to load: ${response.statusText}`);
        }
        return response.text();
      })
      .then((content) => setMarkdownContent(content))
      .catch((error) => console.error('Error loading Markdown file:', error));
  }, []);

  useEffect(() => {
    if (typingIndex < markdownContent.length) {
      const timeout = setTimeout(() => {
        setDisplayedContent((prev) => prev + markdownContent[typingIndex]);
        setTypingIndex((prev) => prev + 1);
      }, 50); // Typing speed (in milliseconds)
      return () => clearTimeout(timeout);
    }
  }, [typingIndex, markdownContent]);

  // Add an extra newline for proper Markdown rendering
  const formattedContent = displayedContent.replace(/\n/g, '\n\n');

  if (!isWhitepaperVisible) return null;

  return (
    <Draggable nodeRef={whitepaperRef} handle=".draggable-header" cancel='.btn'>
      <div
        ref={whitepaperRef}
        className="border-indigo-600 border relative font-bold bg-black border-solid rounded-b-xl m-5 w-screen max-w-3xl cursor-move"
      >
        <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-gray-600">
          <h1 className="text-xl text-white font-bold">DOCS.MD</h1>
          <div className="flex space-x-2">
            <button
              className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleWhitepaper()}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
            <button
              className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleWhitepaper()}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className=" items-center justify-center  mt-4 p-4 text-white max-h-96 overflow-auto">
        <ReactMarkdown
        components={{
            h1: ({ node, ...props }) => (
            <h1 className="text-3xl font-bold text-green-500 text-glow-green mt-4" {...props}>
                {props.children?.length > 0 ? props.children : '\u00A0'}
            </h1>
            ),

            h2: ({ node, ...props }) => (
            <h2 className="text-2xl font-semibold text-blue-400 mt-4" {...props}>
                {props.children?.length > 0 ? props.children : '\u00A0'}
            </h2>
            ),
            h3: ({ node, ...props }) => (
            <h3 className="text-xl font-medium text-green-400 mt-2" {...props}>
                {props.children?.length > 0 ? props.children : '\u00A0'}
            </h3>
            ),
        }}
        >
        {formattedContent}
        </ReactMarkdown>

        </div>
      </div>
    </Draggable>
  );
};

export default Whitepaper;

