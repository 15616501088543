import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { TokenListProvider } from '@solana/spl-token-registry';
import Draggable from 'react-draggable';
import { useWindowContext } from '../context/WindowContext';
import { XMarkIcon, MinusIcon, ArrowUpIcon, ArrowDownIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { useCurrency, SUPPORTED_CURRENCIES, CurrencyCode } from '../context/CurrencyContext';
import SolUI from './SolUI';
import { useSolPrice } from '../context/SolContext';

const QUICKNODE_RPC_URL = process.env.REACT_APP_SOLANA_RPC_URL;

const WalletChecker: React.FC = () => {
  const [solBalance, setSolBalance] = useState<number>(0);
  const [tokenBalances, setTokenBalances] = useState<TokenBalances>({});
  const [tokenMetadata, setTokenMetadata] = useState<Map<string, TokenMetadata>>(new Map());
  const [tokenDetails, setTokenDetails] = useState<TokenDetailsMap>({});
  const [walletAddress, setWalletAddressInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toggleWalletChecker, isWalletCheckerVisible, walletCheckerRef } = useWindowContext();
  const { selectedCurrency, setSelectedCurrency, currencySymbol, convertPrice, isLoadingRates } = useCurrency();
  const { solPrice} = useSolPrice();

  if (!QUICKNODE_RPC_URL) {
    throw new Error('RPC URL is not defined');
  }

  const connection = useMemo(() => new Connection(QUICKNODE_RPC_URL!, 'confirmed'), []);

  const fetchTokenInfo = useCallback(async (mintAddress: string) => {
    try {
      const response = await fetch(`/tokenInfo?address=${mintAddress}`);
      if (!response.ok) {
        throw new Error('Failed to fetch token info');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching token info:', error);
      return null;
    }
  }, []);

  const fetchAllTokenDetails = useCallback(async (tokens: string[]) => {
    setIsLoading(true);
    const details: TokenDetailsMap = {};
    await Promise.all(
      tokens.map(async (mint) => {
        const info = await fetchTokenInfo(mint);
        if (info) {
          details[mint] = info;
        }
      })
    );
    setTokenDetails(details);
    setIsLoading(false);
  }, [fetchTokenInfo]);

  const fetchSolBalance = useCallback(async (): Promise<void> => {
    if (!walletAddress) return;
    try {
      const publicKey = new PublicKey(walletAddress);
      const balance = await connection.getBalance(publicKey);
      setSolBalance(balance / 1e9);
    } catch (error) {
      console.error('Error fetching SOL balance:', error);
    }
  }, [walletAddress, connection]);

  const fetchTokenBalances = useCallback(async (): Promise<void> => {
    if (!walletAddress) return;
    try {
      const publicKey = new PublicKey(walletAddress);
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      });

      const tokenBalancesMap: TokenBalances = {};
      for (const { account } of tokenAccounts.value) {
        const tokenAmount = account.data.parsed.info.tokenAmount;
        const tokenAddress = account.data.parsed.info.mint;
        if (tokenAmount.uiAmount > 0) {
          tokenBalancesMap[tokenAddress] = tokenAmount.uiAmount;
        }
      }
      setTokenBalances(tokenBalancesMap);
      await fetchAllTokenDetails(Object.keys(tokenBalancesMap));
    } catch (error) {
      console.error('Error fetching token balances:', error);
    }
  }, [walletAddress, connection, fetchAllTokenDetails]);

  const fetchBalanceCallback = useCallback(() => {
    fetchSolBalance();
    fetchTokenBalances();
  }, [fetchSolBalance, fetchTokenBalances]);

  useEffect(() => {
    fetchBalanceCallback();
  }, [fetchBalanceCallback]);

  useEffect(() => {
    const fetchTokenMetadata = async () => {
      try {
        const tokens = await new TokenListProvider().resolve();
        const tokenList = tokens.filterByChainId(101).getList();
        const metadataMap = new Map<string, TokenMetadata>();
        tokenList.forEach((token) => {
          metadataMap.set(token.address, {
            symbol: token.symbol,
            name: token.name,
            address: token.address,
          });
        });
        setTokenMetadata(metadataMap);
      } catch (error) {
        console.error('Error fetching token metadata:', error);
      }
    };
    fetchTokenMetadata();
  }, []);

  const calculateTokenValue = (balance: number, price: number | null): number => {
    return price ? balance * price : 0;
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (!walletAddress) {
      setSolBalance(0);
      setTokenBalances({});
      setTokenMetadata(new Map());
      setTokenDetails({});
    }
  }, [walletAddress]);

  if (!isWalletCheckerVisible) return null;

  return (
    <Draggable nodeRef={walletCheckerRef} cancel=".btn,.clickable" handle=".draggable-header">
      <div className="bg-black rounded-b-xl text-white overflow-auto border-indigo-800 w-screen max-w-3xl border z-10" ref={walletCheckerRef}>
        <div className="draggable-header flex items-center justify-between bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-indigo-600 cursor-move">
          <h1 className="text-xl font-bold">wallet-checker.exe</h1>
          <div className="flex space-x-2 items-center">
            <div className="relative">
              <select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value as CurrencyCode)}
                className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded mr-2"
                disabled={isLoadingRates}
              >
                {Object.entries(SUPPORTED_CURRENCIES).map(([code, data]) => (
                  <option key={code} value={code}>
                    {code} ({data.symbol})
                  </option>
                ))}
              </select>
              {isLoadingRates && (
                <div className="absolute right-8 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                </div>
              )}
            </div>
            <button className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => toggleWalletChecker()}>
              <MinusIcon className="w-4 h-4" />
            </button>
            <button className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => toggleWalletChecker()}>
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        <SolUI />

        <div className="p-4 space-y-4 max-h-96 overflow-auto">
          <div className="bg-gray-950 rounded-lg p-3 border border-indigo-500">
            <p className="text-sm text-gray-400">Wallet Address:</p>
            <div className="flex justify-center items-center gap-2">
              <input
                type="text"
                value={walletAddress}
                onChange={(e) => {
                  setWalletAddressInput(e.target.value);
                  fetchSolBalance();
                  fetchTokenBalances();
                }}
                placeholder="Enter wallet address"
                className="w-full p-2 border border-gray-300 rounded text-black max-w-md"
              />
            </div>
            <p className="mt-2 text-xl font-bold text-center">
            {solBalance?.toFixed(5)} SOL (
            {currencySymbol}{convertPrice(solBalance * solPrice)}
            )
            </p>
          </div>

          {isLoading && <p className="text-gray-400">Loading token details...</p>}

          {Object.keys(tokenBalances).length > 0 && (
            <div className="space-y-2">
              <h3 className="text-lg font-bold text-glow-blue">Token Holdings</h3>
              <div className="grid gap-2">
                {Object.entries(tokenBalances).map(([mint, balance]) => {
                  const metadata = tokenMetadata.get(mint);
                  const details = tokenDetails[mint];
                  const dollarValue = calculateTokenValue(balance, details?.priceAction?.currentPrice);
                  const priceChange = details?.priceAction?.priceChange24h || 0;
                  
                  return (
                    <div key={mint} className="bg-gray-950 rounded-lg p-3 hover:border-indigo-400 border border-indigo-500 transition-colors">
                      <div className="flex items-start justify-between">
                        <div className="flex-1">
                          <div className="flex items-center gap-2">
                            <h4 className="font-bold text-glow-blue">
                              {details?.tokenInfo?.symbol || metadata?.symbol || mint.slice(0, 4) + '...' + mint.slice(-4)}
                            </h4>
                            {priceChange !== 0 && (
                              <span className={`text-xs px-2 py-1 rounded ${priceChange >= 0 ? 'bg-green-900 text-green-300' : 'bg-red-900 text-red-300'} flex items-center`}>
                                {priceChange >= 0 ? <ArrowUpIcon className="w-3 h-3 mr-1" /> : <ArrowDownIcon className="w-3 h-3 mr-1" />}
                                {Math.abs(priceChange).toFixed(2)}%
                              </span>
                            )}
                          </div>
                          <p className="text-sm text-gray-400">{details?.tokenInfo?.name || metadata?.name || 'Unknown Token'}</p>
                        </div>
                        <div className="text-right">
                          <p className="font-bold">{balance?.toLocaleString()}</p>
                          <p className="text-sm text-gray-400">
                            {currencySymbol}{convertPrice(dollarValue)}
                          </p>
                        </div>
                      </div>

                      {details && (
                        <div className="mt-2 pt-2 border-t border-gray-700 grid grid-cols-5 gap-2 text-xs">
                          <div className="text-center">
                            <p className="text-gray-400">Price</p>
                            <p className="font-medium">
                              {currencySymbol}{convertPrice(details.priceAction.currentPrice || 0)}
                            </p>
                          </div>
                          <div className="text-center">
                            <p className="text-gray-400">24h Change</p>
                            <p className={`font-medium ${priceChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                              {priceChange ? priceChange.toFixed(2) + '%' : '0.00%'}
                            </p>
                          </div>
                          <div className="text-center">
                            <p className="text-gray-400">Max Transfer</p>
                            <p className="font-medium">
                              {details.tradingActivity.maxTransferAmount?.toLocaleString() || '0'}
                            </p>
                          </div>
                          <div className="text-center">
                            <p className="text-gray-400">Unique Txns</p>
                            <p className="font-medium">
                              S: {details.tradingActivity.uniqueSenders?.toLocaleString() || '0'}
                              <br />
                              R: {details.tradingActivity.uniqueReceivers?.toLocaleString() || '0'}
                            </p>
                          </div>
                          <div className="text-center">
                            <p className="text-gray-400">Tx 24h</p>
                            <p className="font-medium">
                              {details.tradingActivity.transactions24h?.toLocaleString() || '0'}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="flex items-center justify-between mt-2 pt-2 border-t border-gray-700">
                        <p className="text-xs text-gray-500 font-mono truncate flex-1">{mint}</p>
                        <button 
                          className="clickable p-1 hover:bg-gray-600 rounded ml-2"
                          onClick={() => copyToClipboard(mint)}
                        >
                          <DocumentDuplicateIcon className="w-4 h-4 text-gray-400" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default WalletChecker;