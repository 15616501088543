import React  from 'react';
import './styles/css/App.css';
import Main from './pages/main';
import WindowProvider from './context/WindowContext';
import APIProvider from './context/APIContext';
import WalletProvider from './context/WalletContext';
import { CurrencyProvider } from './context/CurrencyContext';
import { SolPriceProvider } from './context/SolContext';

function App() {
  return (
    <APIProvider>
      <WindowProvider>
        <SolPriceProvider>
        <CurrencyProvider>
          <WalletProvider>
            <Main/>
          </WalletProvider>
        </CurrencyProvider>
        </SolPriceProvider>
      </WindowProvider>
    </APIProvider>
  );
}

export default App;
