import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Socials from '../components/Socials';
import background from '../styles/images/background.gif';
import WalletChecker from '../components/WalletChecker';
import Logo from '../components/Logo';
import Taskbar from '../components/Taskbar';
import { useWindowContext } from '../context/WindowContext';
import Shutdown from '../components/Shutdown';
import AI from '../components/AI';
import Whitepaper from '../components/Whitepaper';
import Chat from '../components/ChatApp';
import InteractiveNodes from '../components/Nodes';

function Main() {
  const {
    socialRef,
    walletCheckerRef,
    terminalRef,
    shuttingDown,
    AIRef,
    chatRef,
    whitepaperRef,
    shouldRenderLoading
  } = useWindowContext();

  return (
    <div
      className="App overflow-hidden fixed"
      style={{
        backgroundImage: `url(${background})`,
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
        {/* Interactive Nodes Background */}
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2}}
        >
                  <InteractiveNodes />
        </motion.div>
      {/* Conditionally render the shutdown overlay */}
      <AnimatePresence>{shuttingDown && <Shutdown />}</AnimatePresence>

      {/* Main Content */}
      {!shuttingDown && !shouldRenderLoading &&  (
        <motion.div
          className="gradient-scrollbar h-screen w-full flex flex-col items-center justify-start relative bg-repeat overflow-y-auto overflow-x-auto bottom-16"
          initial={{ opacity: 0.5 }}
          animate={{ opacity: [0.95, 1, 0.95] }}
          transition={{ duration: 3, repeat: Infinity, ease: 'linear' }}
        >
          {/* Logo Section */}
          <motion.div
            className="mt-20 p-5 text-center rounded-3xl m-5 max-h-full w-full sm:w-auto"
            ref={terminalRef}
            initial={{ opacity: 0, y: '-100vh' }} // Fully off-screen top
            animate={{ opacity: 1, y: 0 }} // Animates to on-screen position
            transition={{ duration: 0.2 }}
            style={{ zIndex: 0 }}
          >
            <Logo />
          </motion.div>

          {/* Socials Section */}
          <motion.div
            className="mb-5 w-full px-4 sm:max-w-md"
            ref={socialRef}
            initial={{ opacity: 0, y: '-100vh' }} // Fully off-screen top
            animate={{ opacity: 1, y: 0 }} // Animates to on-screen position
            transition={{ duration: 0.2, delay: 0.1 }}
            style={{ zIndex: 0 }}
          >
            <Socials />
          </motion.div>

          {/* Wallet Checker Section */}
          <motion.div
            className="mb-5 w-full px-4 sm:max-w-md"
            ref={walletCheckerRef}
            initial={{ opacity: 0, y: '100vh' }} // Fully off-screen bottom
            animate={{ opacity: 1, y: 0 }} // Animates to on-screen position
            transition={{ duration: 0.8, delay: 1.5 }}
            style={{ zIndex: 0 }}
          >
            <WalletChecker />
          </motion.div>

          {/* AI Question Section */}
          <motion.div
            className="mb-5 w-full px-4 sm:max-w-md"
            ref={AIRef}
            initial={{ opacity: 0, x: '100vw' }} // Fully off-screen right
            animate={{ opacity: 1, x: 0 }} // Animates to on-screen position
            transition={{ duration: 0.8, delay: 2 }}
            style={{ zIndex: 0 }}
          >
            <AI />
          </motion.div>

          {/* Whitepaper Section */}
          <motion.div
            className="mb-5 w-full px-4 sm:max-w-md"
            ref={whitepaperRef}
            initial={{ opacity: 0, y: '100vh' }} // Fully off-screen bottom
            animate={{ opacity: 1, y: 0 }} // Animates to on-screen position
            transition={{ duration: 0.2, delay: 1 }}
            style={{ zIndex: 0 }}
          >
            <Whitepaper />
          </motion.div>

          {/* Chat Section */}
          <motion.div
            className="mb-5 w-full px-4 sm:max-w-md"
            ref={chatRef}
            initial={{ opacity: 0, y: '100vh' }} // Fully off-screen bottom
            animate={{ opacity: 1, y: 0 }} // Animates to on-screen position
            transition={{ duration: 0.2, delay: 1 }}
            style={{ zIndex: 0 }}
          >
            <Chat />
          </motion.div>

          {/* Taskbar Section */}
          <motion.div
          className="w-full fixed bottom-0 bg-black/80 z-20"
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8}}
        >
          <Taskbar />
        </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default Main;
