import React, { createContext, useState, useContext } from 'react';


// Create the Context
const WalletContext = createContext<WalletContextType | undefined>(undefined);


// WalletProvider Component
const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);

    // Phantom wallet connection
    const connectWallet = async () => {
      try {
        if (!window.solana || !window.solana.isPhantom) {
          alert('Phantom wallet not detected. Please install it.');
          return;
        }
  
        const response = await window.solana.connect();
        setWalletAddress(response.publicKey.toString());
      } catch (error) {
        console.error('Wallet connection failed:', error);
      }
    };

  return (
    <WalletContext.Provider value={{ walletAddress, setWalletAddress,connectWallet }}>
      {children}
    </WalletContext.Provider>
  );
};

// Custom hook to use the WalletContext
export const useWalletContext = (): WalletContextType => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWalletContext must be used within a WalletProvider');
  }
  return context;
};

export default WalletProvider;

