import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

// Types
export const SUPPORTED_CURRENCIES = {
  USD: { symbol: '$' },
  EUR: { symbol: '€' },
  GBP: { symbol: '£' },
  JPY: { symbol: '¥' },
  AUD: { symbol: 'A$' },
  NZD: { symbol: 'NZ$' }
} as const;

export type CurrencyCode = keyof typeof SUPPORTED_CURRENCIES;
type ExchangeRates = Partial<Record<CurrencyCode, number>>;



// Context
const CurrencyContext = createContext<CurrencyContextType | null>(null);

// Cache duration (1 hour)
const CACHE_DURATION = 1000 * 60 * 60;

// Provider Component
export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(() => {
    const saved = localStorage.getItem('preferredCurrency');
    return (saved as CurrencyCode) || 'USD';
  });
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({});
  const [isLoadingRates, setIsLoadingRates] = useState(false);

  const fetchExchangeRates = useCallback(async () => {
    try {
      const cachedRates = localStorage.getItem('exchangeRates');
      const cachedTimestamp = localStorage.getItem('exchangeRatesTimestamp');
      
      if (cachedRates && cachedTimestamp) {
        const timestamp = parseInt(cachedTimestamp);
        if (Date.now() - timestamp < CACHE_DURATION) {
          setExchangeRates(JSON.parse(cachedRates));
          return;
        }
      }

      setIsLoadingRates(true);
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      console.log("Currency pulls: ", response)
      if (!response.ok) throw new Error('Failed to fetch exchange rates');
      
      const data = await response.json();
      const rates: ExchangeRates = {};
      
      // Only store rates for supported currencies
      Object.keys(SUPPORTED_CURRENCIES).forEach(currency => {
        rates[currency as CurrencyCode] = currency === 'USD' ? 1 : data.rates[currency];
      });

      setExchangeRates(rates);
      localStorage.setItem('exchangeRates', JSON.stringify(rates));
      localStorage.setItem('exchangeRatesTimestamp', Date.now().toString());
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      // Fallback to cached rates if they exist
      const cachedRates = localStorage.getItem('exchangeRates');
      if (cachedRates) {
        setExchangeRates(JSON.parse(cachedRates));
      }
    } finally {
      setIsLoadingRates(false);
    }
  }, []);

  // Fetch rates on component mount
  useEffect(() => {
    fetchExchangeRates();
  }, [fetchExchangeRates]);

  // Save currency preference when it changes
  useEffect(() => {
    localStorage.setItem('preferredCurrency', selectedCurrency);
  }, [selectedCurrency]);

  // Currency conversion helper
  const convertPrice = useCallback((usdAmount: number): string => {
    if (!usdAmount || !exchangeRates[selectedCurrency]) return '0.00';
    const converted = usdAmount * (exchangeRates[selectedCurrency] || 1);
    return converted.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }, [selectedCurrency, exchangeRates]);

  const value = {
    selectedCurrency,
    setSelectedCurrency,
    currencySymbol: SUPPORTED_CURRENCIES[selectedCurrency].symbol,
    convertPrice,
    exchangeRates,
    isLoadingRates,
    refreshRates: fetchExchangeRates
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook for using the currency context
export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};