import React, { createContext, useState, useContext, useRef} from 'react';



// Create the Context
export const WindowContext = createContext<WindowContextType | undefined>(undefined);


// WindowProvider Component
const WindowProvider: React.FC<WindowProviderProps> = ({ children }) => {
  const [isSocialVisible, setIsSocialVisible] = useState<boolean>(false);
  const [isWalletCheckerVisible, setIsWalletCheckerVisible] = useState<boolean>(false);
  const [isAIVisible, setIsAIVisible] = useState<boolean>(false);
  const [isWhitepaperVisible, setIsWhitepaperVisible] = useState<boolean>(false);
  const [isXVisible, setIsXVisible] = useState<boolean>(false);
  const [chatVisible, setChatVisible] = useState<boolean>(false);
  const [isTerminalVisible, setIsTerminalVisible] = useState<boolean>(true);
  const [shuttingDown, setShuttingDown] = useState<boolean>(false);
  const [shouldRenderLoading, setshouldRenderLoading] = useState<boolean>(true);

  // Refs
  const socialRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const walletCheckerRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const terminalRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const AIRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const whitepaperRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const XRef =  useRef<HTMLDivElement>({} as HTMLDivElement);
  const chatRef =  useRef<HTMLDivElement>({} as HTMLDivElement);

  // Toggle functions
  const toggleSocial = () => setIsSocialVisible((prev) => !prev);
  const toggleWalletChecker = () => setIsWalletCheckerVisible((prev) => !prev);
  const toggleAI = () => setIsAIVisible((prev) => !prev);
  const toggleWhitepaper = () => setIsWhitepaperVisible((prev) => !prev);
  const toggleXBrowser = () => setIsXVisible((prev) => !prev);
  const toggleChat = () => setChatVisible((prev) => !prev);
  const toggleTerminal = () => setIsTerminalVisible((prev) => !prev);

  return (
    <WindowContext.Provider
      value={{
        isSocialVisible,
        isWalletCheckerVisible,
        toggleSocial,
        toggleWalletChecker,
        setIsSocialVisible,
        setIsWalletCheckerVisible,
        socialRef,
        walletCheckerRef,
        shuttingDown,
        setShuttingDown,
        terminalRef,
        isAIVisible,
        setIsAIVisible,
        AIRef,
        toggleAI,
        isWhitepaperVisible,
        setIsWhitepaperVisible,
        whitepaperRef,
        toggleWhitepaper,
        isXVisible,
        setIsXVisible,
        toggleXBrowser,
        XRef,
        toggleChat,
        chatVisible,
        setChatVisible,
        chatRef,
        isTerminalVisible,
        setIsTerminalVisible,
        toggleTerminal,
        shouldRenderLoading, setshouldRenderLoading
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

// Custom hook to use the WindowContext
export const useWindowContext = (): WindowContextType => {
  const context = useContext(WindowContext);
  if (!context) {
    throw new Error('useWindowContext must be used within a WindowProvider');
  }
  return context;
};

export default WindowProvider;
