import React, { useState, useEffect } from 'react';
import { ReactTyped } from 'react-typed';
import { XMarkIcon, MinusIcon, CpuChipIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useWindowContext } from '../context/WindowContext';
import Draggable from 'react-draggable';
import { useWalletContext } from '../context/WalletContext';
import {availableCommands, infoText} from '../utils/commandText';



const Logo = () => {
  const [isTitleTyped, setIsTitleTyped] = useState(false);
  const [chevronVisible, setChevronVisible] = useState(true);
  const [inputValue, setInputValue] = useState('');


  const [history, setHistory] = useState<CommandHistoryEntry[]>([
    {
      command: '/help',
      output: availableCommands,
    },
  ]);
  const [lastLaunchedRef, setLastLaunchedRef] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const { walletAddress, connectWallet } = useWalletContext();

  

  const {
    socialRef,
    walletCheckerRef,
    terminalRef,
    AIRef,
    whitepaperRef,
    chatRef,
    toggleSocial,
    isSocialVisible,
    toggleWalletChecker,
    isWalletCheckerVisible,
    toggleTerminal,
    toggleAI,
    isAIVisible,
    toggleWhitepaper,
    isWhitepaperVisible,
    toggleChat,
    chatVisible,
    isTerminalVisible,
  } = useWindowContext();

  // Chevron blinking effect
  useEffect(() => {
    const interval = setInterval(() => {
      setChevronVisible((prev) => !prev);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  // Scroll to the launched component
  useEffect(() => {
    if (lastLaunchedRef?.current) {
      lastLaunchedRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [lastLaunchedRef]);

  const handleCommand = () => {
    const command = inputValue.trim();
    let output = '';

    if (command.startsWith('/launch')) {
      const appName = command.split(' ')[1]?.toLowerCase();
      let refToScrollTo: React.RefObject<HTMLDivElement> | null = null;

      switch (appName) {
        case 'social':
          if (!isSocialVisible) {
            toggleSocial();
            output = `Launching Social App...`;
          } else {
            output = `ERROR: Social App is already open.`;
          }
          refToScrollTo = socialRef;
          break;
        case 'wallet':
          if (!isWalletCheckerVisible) {
            toggleWalletChecker();
            output = `Launching Wallet Checker...`;
          } else {
            output = `ERROR: Wallet Checker is already open.`;
          }
          refToScrollTo = walletCheckerRef;
          break;
        case 'ai':
          if (!isAIVisible) {
            toggleAI();
            output = `Launching AI Assistant...`;
          } else {
            output = `ERROR: AI Assistant is already open.`;
          }
          refToScrollTo = AIRef;
          break;
        case 'doc':
          if (!isWhitepaperVisible) {
            toggleWhitepaper();
            output = `Launching Docs Viewer...`;
          } else {
            output = `ERROR: Whitepaper Viewer is already open.`;
          }
          refToScrollTo = whitepaperRef;
          break;
        case 'chat':
          if (!chatVisible) {
            toggleChat();
            output = `Launching Chat App...`;
          } else {
            output = `ERROR: Chat App is already open.`;
          }
          refToScrollTo = chatRef;
          break;
        case 'terminal':
          output = `ERROR: Terminal is already open.`;
          break;
        default:
          output = `Invalid app name: ${appName || 'none'}. Available apps: social, wallet, ai, doc, chat, terminal.`;
      }

      setLastLaunchedRef(refToScrollTo);
    } else {
      switch (command) {
        case '/help':
          output = availableCommands;
          break;
        case '/info':
          output = infoText;
          break;
        case '/clear':
          setHistory([]);
          setInputValue('');
          return;
        default:
          output = `Unknown command: ${command}. Use /help for available commands.`;
      }
    }

    setHistory((prev) => [...prev, { command, output }]);
    setInputValue('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCommand();
    }
  };

  if (!isTerminalVisible) return null;

  return (
    <Draggable nodeRef={terminalRef} handle=".draggable-header" cancel=".btn">
      <div ref={terminalRef} className="bg-black border border-indigo-800 rounded-xl cursor-move  max-w-lg">
        {/* Header */}
        <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-indigo-600">
          <h1 className="text-xl text-white font-bold">
            <CpuChipIcon className="w-6 h-6 inline-block" />
            <span className="ml-2">app.exe</span>
          </h1>
          <div className="flex space-x-2">
            <button
              className="btn bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleTerminal()}
            >
              <MinusIcon className="w-4 h-4" />
            </button>
            <button
              className="btn bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => toggleTerminal()}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="p-4 overflow-auto  sm:max-h-96">
          {/* Title */}
          <h1 className="font-bold text-3xl sm:text-5xl md:text-6xl lg:text-7xl text-green-500 text-glow-green p-4">
            <ReactTyped
              strings={['Gnosis.AI']}
              showCursor={false}
              typeSpeed={200}
              onComplete={() => setIsTitleTyped(true)}
            />
          </h1>

          {/* Input and Output */}
          {isTitleTyped && (
            <div className="p-4">
              {/* Wallet Connection */}
              <div className="my-4 flex items-center">
                {walletAddress ? (
                  <div className="text-green-500 text-glow-green font-bold">
                    Connected: {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
                  </div>
                ) : (
                  <button
                    className="btn border-dashed border border-green-500 text-green-500 hover:text-green-400 hover:bg-green-400/10 font-bold py-2 px-4 rounded"
                    onClick={connectWallet}
                  >
                    /Connect Wallet
                  </button>
                )}
              </div>
              <div className="text-green-500 text-glow-green font-mono whitespace-pre-wrap text-left">
                {history.map((entry, index) => (
                  <div key={index} className="mb-2">
                    <div className="font-bold">$ {entry.command}</div>
                    <ReactTyped
                      strings={[entry.output]}
                      typeSpeed={40}
                      showCursor={false}
                      className="text-left"
                    />
                  </div>
                ))}
              </div>

              <div className="flex items-center mt-4">
                <ChevronRightIcon
                  className={`text-green-500 text-glow-green w-6 h-6 transition-opacity duration-500 ${
                    chevronVisible ? 'opacity-100' : 'opacity-0'
                  }`}
                />
                <input
                  type="text"
                  className="bg-transparent text-green-500 outline-none w-full caret-green-500"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default Logo;
