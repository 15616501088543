import React, { useRef, useEffect, useState } from "react";
import { useApiContext } from "../context/APIContext";
import { Loading } from "./Loading";
import { Node, CachedData } from '../types/node';

const CACHE_DURATION = 1000 * 60 * 60 * 24; // 1 hour in milliseconds
const CACHE_KEY = 'nodeTextData';

const InteractiveNodes: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [textData, setTextData] = useState<string[]>([]);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(false);
  const animationRef = useRef<number>(0);
  const { askAI } = useApiContext();
  const dataFetchedRef = useRef<boolean>(false);

  const getCachedData = (): string[] | null => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const parsedCache: CachedData = JSON.parse(cached);
    const now = new Date().getTime();

    if (now - parsedCache.timestamp < CACHE_DURATION) {
      return parsedCache.data;
    }
    
    localStorage.removeItem(CACHE_KEY);
    return null;
  };

  const setCachedData = (data: string[]) => {
    const cacheData: CachedData = {
      timestamp: new Date().getTime(),
      data: data
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };

  useEffect(() => {
    const fetchText = async () => {
      // Prevent multiple fetches
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      // Check cache first
      const cachedData = getCachedData();
      if (cachedData) {
        setTextData(cachedData);
        return;
      }

      // Only show loading if we need to fetch
      setLoadingVisible(true);
      
      try {
        const response = await askAI("Generate text for nodes", "node");
        const newData = response.nodes[0].split("\n").filter((text: string) => text.trim());
        
        if (newData.length > 0) {
          setTextData(newData);
          setCachedData(newData);
        }
      } catch (error) {
        console.error("Failed to fetch AI text:", error);
        // Fallback data in case of error
        const fallbackData = ["Node 1", "Node 2", "Node 3", "Node 4", "Node 5"];
        setTextData(fallbackData);
        setCachedData(fallbackData);
      }
    };

    fetchText();
  }, [askAI]);

  useEffect(() => {
    if (textData.length > 0) {
      setTimeout(() => {
        setLoadingVisible(false);
      }, 1000);
    }
  }, [textData]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || textData.length === 0) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Rest of the canvas setup and animation code remains the same
    const nodes: Node[] = [];
    const mouse = { x: 0, y: 0 };
    const speed = 0.4;

    const setupCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setupCanvas();

    for (let i = 0; i < 100; i++) {
      nodes.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 2,
        dx: (Math.random() * 2 - 1) * speed,
        dy: (Math.random() * 2 - 1) * speed,
      });
    }

    nodes.forEach((node, index) => {
      if (index < textData.length) {
        node.text = textData[index];
      }
    });

    const handleMouseMove = (e: MouseEvent) => {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      nodes.forEach((node) => {
        node.x += node.dx;
        node.y += node.dy;

        if (node.x < 0 || node.x > canvas.width) node.dx *= -1;
        if (node.y < 0 || node.y > canvas.height) node.dy *= -1;

        ctx.beginPath();
        ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(199, 210, 254, 0.4)";
        ctx.fill();

        nodes.forEach((otherNode) => {
          const distance = Math.sqrt(
            (node.x - otherNode.x) ** 2 + (node.y - otherNode.y) ** 2
          );
          if (distance < 170) {
            ctx.beginPath();
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(otherNode.x, otherNode.y);
            ctx.strokeStyle = "rgba(99, 102, 241, 0.1)";
            ctx.lineWidth = 1;
            ctx.stroke();
          }
        });

        const distanceToMouse = Math.sqrt(
          (mouse.x - node.x) ** 2 + (mouse.y - node.y) ** 2
        );
        if (distanceToMouse < 150) {
          ctx.beginPath();
          ctx.moveTo(node.x, node.y);
          ctx.lineTo(mouse.x, mouse.y);
          ctx.strokeStyle = "rgba(99, 102, 241, 0.5)";
          ctx.lineWidth = 1;
          ctx.stroke();
        }

        if (node.text) {
          const textWidth = ctx.measureText(node.text).width;
          const padding = 10;
          const rectWidth = textWidth + padding * 2;
          const rectHeight = 30;
          const radius = 10;

          ctx.beginPath();
          ctx.moveTo(node.x - rectWidth / 2 + radius, node.y - 40);
          ctx.arcTo(
            node.x + rectWidth / 2,
            node.y - 40,
            node.x + rectWidth / 2,
            node.y - 40 + rectHeight,
            radius
          );
          ctx.arcTo(
            node.x + rectWidth / 2,
            node.y - 40 + rectHeight,
            node.x - rectWidth / 2,
            node.y - 40 + rectHeight,
            radius
          );
          ctx.arcTo(
            node.x - rectWidth / 2,
            node.y - 40 + rectHeight,
            node.x - rectWidth / 2,
            node.y - 40,
            radius
          );
          ctx.arcTo(
            node.x - rectWidth / 2,
            node.y - 40,
            node.x + rectWidth / 2,
            node.y - 40,
            radius
          );
          ctx.closePath();
          ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
          ctx.fill();

          ctx.shadowBlur = 15;
          ctx.shadowColor = "rgba(99, 102, 241, 0.8)";
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;

          ctx.font = "14px Arial";
          ctx.fillStyle = "rgba(99, 102, 241, 1)";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(node.text, node.x, node.y - 25);

          ctx.shadowBlur = 0;
          ctx.shadowColor = "transparent";
        }
      });

      animationRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      setupCanvas();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, [textData]);

  return (
    <>
      <canvas ref={canvasRef} className="fixed top-0 left-0 z-0 pointer-events-none" />
      <Loading isVisible={loadingVisible} />
    </>
  );
};

export default InteractiveNodes;