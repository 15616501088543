import React, { useEffect, useState } from 'react';
import { useWindowContext } from '../context/WindowContext';
import { motion } from 'framer-motion';
import { ReactTyped } from 'react-typed';

const Shutdown: React.FC = () => {
  const { setShuttingDown, shuttingDown } = useWindowContext();
  const [shutdownMessages, setShutdownMessages] = useState<string[]>([
    'INITIATING SHUTDOWN SEQUENCE...',
    'ERROR: CRITICAL SYSTEM FAILURE',
    'SHUTDOWN PROTOCOL TERMINATED'
  ]);
  const [messageComplete, setMessageComplete] = useState<boolean>(false);

  useEffect(() => {
    if (shuttingDown) {
      setShutdownMessages([
        'INITIATING SHUTDOWN SEQUENCE...',
        'ERROR: CRITICAL SYSTEM FAILURE',
        'SHUTDOWN PROTOCOL TERMINATED'
      ]);
    }
  }, [shuttingDown]);

  useEffect(() => {
    const handleKeyPress = () => {
      if (messageComplete) {
        setShuttingDown(false);
        setMessageComplete(false);
      }
    };

    const handleTouchEvent = () => {
      if (messageComplete) {
        setShuttingDown(false);
        setMessageComplete(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('touchstart', handleTouchEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('touchstart', handleTouchEvent);
    };
  }, [messageComplete, setShuttingDown]);

  return (
    <motion.div
      className="fixed inset-0 bg-indigo-950/70 flex items-center justify-center z-50 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* CRT Screen Effects */}
      <div className="absolute inset-0">
        {/* Scan lines */}
        <div 
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.3) 50%)',
            backgroundSize: '100% 4px',
            animation: 'scanlines 0.12s linear infinite',
            zIndex: 2
          }}
        />
        
        {/* Flicker effect */}
        <div 
          className="absolute inset-0 bg-green-500/5"
          style={{
            animation: 'flicker 0.15s infinite',
            mixBlendMode: 'overlay',
            zIndex: 1
          }}
        />

        {/* Vignette effect */}
        <div 
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(circle, transparent 10%, black 90%)',
            zIndex: 3
          }}
        />
      </div>

      {/* Content */}
      <motion.div
        className="relative z-10 text-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="font-mono relative">
          <ReactTyped
            strings={shutdownMessages}
            typeSpeed={60}
            backSpeed={40}
            backDelay={1000}
            loop={false}
            cursorChar="_"
            onComplete={() => setMessageComplete(true)}
            className="text-3xl font-bold tracking-wider"
            style={{
              color: '#00ff00',
              textShadow: '0 0 10px rgba(0, 255, 0, 0.7)',
            }}
          />
          {messageComplete && (
            <div className="mt-8 text-xl">
              <ReactTyped
                strings={['PRESS ANY KEY TO CONTINUE...']}
                typeSpeed={50}
                loop={false}
                cursorChar="_"
                className="tracking-wider"
                style={{
                  color: '#00ff00',
                  textShadow: '0 0 8px rgba(0, 255, 0, 0.7)',
                }}
              />
            </div>
          )}
        </div>
      </motion.div>

      <style>
        {`
          @keyframes scanlines {
            0% { transform: translateY(0); }
            100% { transform: translateY(4px); }
          }

          @keyframes flicker {
            0% { opacity: 0.27861; }
            5% { opacity: 0.34769; }
            10% { opacity: 0.23604; }
            15% { opacity: 0.90626; }
            20% { opacity: 0.18128; }
            25% { opacity: 0.83891; }
            30% { opacity: 0.65583; }
            35% { opacity: 0.67807; }
            40% { opacity: 0.26559; }
            45% { opacity: 0.84693; }
            50% { opacity: 0.96019; }
            55% { opacity: 0.08594; }
            60% { opacity: 0.20313; }
            65% { opacity: 0.71988; }
            70% { opacity: 0.53455; }
            75% { opacity: 0.37288; }
            80% { opacity: 0.71428; }
            85% { opacity: 0.70419; }
            90% { opacity: 0.7003; }
            95% { opacity: 0.36108; }
            100% { opacity: 0.24387; }
          }

          @keyframes textShadow {
            0% { text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            5% { text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            10% { text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            15% { text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            20% { text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            25% { text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            30% { text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            35% { text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            40% { text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            45% { text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            50% { text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            55% { text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            60% { text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            65% { text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            70% { text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            75% { text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            80% { text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            85% { text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            90% { text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            95% { text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
            100% { text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px; }
          }
        `}
      </style>
    </motion.div>
  );
};

export default Shutdown;