import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';


  
  // Create context with default values
  const SolPriceContext = createContext<SolPriceContextType>({
    solPrice: 0,
    loading: true
  });
  
  
  export const useSolPrice = () => useContext(SolPriceContext);
  
  export const SolPriceProvider: React.FC<SolPriceProviderProps> = ({ children }) => {
    const [price, setPrice] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      const fetchSolPrice = async () => {
        try {
          const response = await axios.get(
            'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
          );
          setPrice(response.data.solana.usd);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching SOL price:', error);
          setLoading(false);
        }
      };
  
      // Fetch price immediately
      fetchSolPrice();
  
      // Set up an interval to refresh the price
      const interval = setInterval(fetchSolPrice, 60000); // Refresh every 60 seconds
  
      // Cleanup function to clear the interval on unmount
      return () => clearInterval(interval);
    }, []);
  
    return (
      <SolPriceContext.Provider value={{ solPrice: price, loading }}>
        {children}
      </SolPriceContext.Provider>
    );
  };