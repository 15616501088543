export const availableCommands = `
  Available Commands:
  - /help: Show available commands
  - /info: Display application info
  - /clear: Clear the terminal
  - /launch [app name]: Launch a specific app.
`;

export const infoText =  `
Gnosis.AI
Version: 1.0.0
An agent designed to expand its knowledge and capabilities.
        `

