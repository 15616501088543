import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';


// Create a Context for the API
const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Custom hook to use the API context
export const useApiContext = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApiContext must be used within an ApiProvider');
  }
  return context;
};

// Props for the ApiProvider component
interface ApiProviderProps {
  children: ReactNode;
}

// API Provider component
const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<string>('');
  const [type, setType] = useState<'text' | 'image' | ''>('');

  // Generate a new sessionId if it doesn't exist
  const generateSessionId = (): string => {
    return Math.random().toString(36).substr(2, 9); // Create a random session ID
  };

  // Get the sessionId from localStorage or generate a new one
  const sessionId: string =
    localStorage.getItem('sessionId') || generateSessionId();

  useEffect(() => {
    if (!localStorage.getItem('sessionId')) {
      localStorage.setItem('sessionId', sessionId); // Store sessionId in localStorage
    }
  }, [sessionId]);

  // Function to call the Netlify function that interacts with OpenAI
  const askAI = async (question: string, type: 'text' | 'image' | 'node'): Promise<AskAIResponse> => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post('/ask-ai', {
        question,
        type,
        sessionId,
      });
      console.log('Nodes:', result.data.nodes);
      const nodes = result.data.nodes ? result.data.nodes : []; // Extract texts from response
      if(type !== 'node'){
        setResponse(result.data.answer || result.data.image);
        setType(result.data.answer ? 'text' : 'image');
      }
  
      return { nodes }; // Return the formatted response
    } catch (err) {
      setError('Error fetching AI response.');
      console.error('Error:', err);
      return { nodes: [] }; // Return an empty response on error
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <ApiContext.Provider
      value={{
        loading,
        error,
        response,
        askAI,
        type,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
