import React, { useEffect } from "react";
import { ReactTyped } from "react-typed";
import { useWindowContext } from "../context/WindowContext";

export const Loading = ({ isVisible }: { isVisible: boolean }) => {
  const { shouldRenderLoading, setshouldRenderLoading } = useWindowContext();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isVisible) {
      timeout = setTimeout(() => setshouldRenderLoading(false), 1000);
    } else {
      setshouldRenderLoading(true);
    }
    return () => clearTimeout(timeout);
  }, [isVisible, setshouldRenderLoading]);

  if (!shouldRenderLoading) return null;

  const bootMessages = [
    "INITIALIZING...",
    "SYSTEM READY"
  ];

  return (
    <div
      className="bg-black w-screen h-screen fixed top-0 left-0 z-50 transition-opacity duration-1000 ease-out overflow-hidden flex items-center justify-center"
      style={{
        opacity: isVisible ? "1" : "0",
      }}
    >
      {/* CRT screen effect overlay */}
      <div className="absolute inset-0 pointer-events-none bg-gradient-radial from-black via-gray-950 to-black" />
      
      {/* Phosphor glow effect */}
      <div className="absolute inset-0 pointer-events-none bg-green-500/5" 
        style={{
          filter: "blur(40px)",
          animation: "phosphor 2s ease-in-out infinite"
        }}
      />

      {/* Scan lines effect */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          background: "repeating-linear-gradient(0deg, rgba(0,0,0,0.15) 0px, rgba(0,0,0,0.15) 1px, transparent 1px, transparent 2px)",
          backgroundSize: "100% 2px",
          animation: "scanlines 0.1s linear infinite",
        }}
      />

      {/* Screen flicker */}
      <div 
        className="absolute inset-0 pointer-events-none bg-indigo-500/10"
        style={{
          animation: "flicker 0.15s infinite"
        }}
      />

      {/* Terminal content */}
      <div className="font-mono text-green-500 text-glow-green relative text-center z-10">
        <ReactTyped
          strings={bootMessages}
          typeSpeed={50}
          backSpeed={0}
          fadeOut={false}
          loop={false}
          showCursor={true}
          cursorChar="_"
          className="text-2xl"
        />
      </div>

      <style>
        {`
          @keyframes scanlines {
            0% { transform: translateY(0); }
            100% { transform: translateY(2px); }
          }
          @keyframes flicker {
            0% { opacity: 0.27861; }
            5% { opacity: 0.34769; }
            10% { opacity: 0.23604; }
            15% { opacity: 0.90626; }
            20% { opacity: 0.18128; }
            25% { opacity: 0.83891; }
            30% { opacity: 0.65583; }
            35% { opacity: 0.67807; }
            40% { opacity: 0.26559; }
            45% { opacity: 0.84693; }
            50% { opacity: 0.96019; }
            55% { opacity: 0.08594; }
            60% { opacity: 0.20313; }
            65% { opacity: 0.71988; }
            70% { opacity: 0.53455; }
            75% { opacity: 0.37288; }
            80% { opacity: 0.71428; }
            85% { opacity: 0.70419; }
            90% { opacity: 0.7003; }
            95% { opacity: 0.36108; }
            100% { opacity: 0.24387; }
          }
          @keyframes phosphor {
            0% { opacity: 0.4; }
            50% { opacity: 0.6; }
            100% { opacity: 0.4; }
          }
        `}
      </style>
    </div>
  );
};

export default Loading;