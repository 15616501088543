import React, { useState, useEffect } from 'react';
import {
  FaWallet,
  FaWifi,
  FaPowerOff,
  FaDev,
  FaRobot,
  FaGlobeAmericas,
  FaFileCode,
} from 'react-icons/fa';
import { RxDividerVertical } from 'react-icons/rx';
import { PiChatCenteredDotsDuotone } from 'react-icons/pi';
import { useWindowContext } from '../context/WindowContext';

const Taskbar = () => {
  const {
    toggleSocial,
    toggleWalletChecker,
    toggleAI,
    toggleWhitepaper,
    setShuttingDown,
    toggleChat,
    toggleTerminal,
  } = useWindowContext();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [showShutdownDialog, setShowShutdownDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const formattedDate = currentTime.toLocaleDateString();

  const apps = [
    { name: 'Sol Wallet', icon: <FaWallet className="text-xl" />, action: toggleWalletChecker },
    { name: 'Socials', icon: <FaGlobeAmericas className="text-xl" />, action: toggleSocial },
    { name: 'Terminal', icon: <FaDev className="text-xl" />, action: toggleTerminal },
    { name: 'Gnosis', icon: <FaRobot className="text-xl" />, action: toggleAI },
    { name: 'Chat', icon: <PiChatCenteredDotsDuotone className="text-xl" />, action: toggleChat },
    { name: 'Docs', icon: <FaFileCode className="text-xl" />, action: toggleWhitepaper },
  ];

  const filteredApps = apps.filter((app) =>
    app.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {/* Taskbar */}
      <nav className="bg-gradient-to-r from-blue-950/80 via-indigo-800/80 to-violet-950/80 text-white fixed bottom-0 left-0 right-0 border-indigo-200 border-t">
        <div className="flex flex-row justify-between items-center sm:overflow-x-auto gradient-scrollbar">
          {/* Left Side: System Icons */}
          <div className="flex items-center space-x-2 overflow-x-auto sm:overflow-visible">
            <button
              className="flex flex-col items-center px-2 py-2 rounded-md hover:text-red-500"
              onClick={() => setShowShutdownDialog(true)}
            >
              <FaPowerOff className="text-xl ml-10" />
            </button>
            <RxDividerVertical className="text-4xl" />

            {/* Search Bar */}
            <div className="relative  items-center hidden sm:flex">
              <input
                type="text"
                placeholder="Search..."
                className="px-4 py-1 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <RxDividerVertical className="text-4xl" />
            {filteredApps.map((app, index) => (
              <button
                key={index}
                className="flex flex-col items-center px-2 py-2 rounded-md hover:text-indigo-300"
                onClick={app.action}
              >
                {app.icon}
                <span className="text-xs text-glow">{app.name}</span>
              </button>
            ))}
          </div>

          {/* Right Side: Wi-Fi and Date/Time */}
          <div className="flex items-center space-x-4 pr-4 mr-4">
            <RxDividerVertical className="text-4xl" />
            <div className="relative group flex items-center">
              <div className="relative flex items-center">
                <FaWifi className="text-xl" />
                <span className="absolute top-0 right-0 h-2 w-2 bg-yellow-500 rounded-full border border-white" />
              </div>
              <div className="absolute bottom-14 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-blue-700/30 via-indigo-500/30 to-violet-500/30 text-white text-sm rounded px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity w-48">
                Wi-Fi Connected
              </div>
            </div>
            <div className="relative group text-sm text-right hidden sm:block">
              <div className="relative">
                <p>{formattedDate}</p>
                <p>{formattedTime}</p>
              </div>
              <div
                className="bg-gradient-to-r from-blue-700/30 via-indigo-500/30 to-violet-500/30 absolute bottom-16 text-white text-sm rounded px-4 py-1 opacity-0 group-hover:opacity-100 transition-opacity"
                style={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                  whiteSpace: 'nowrap',
                  maxWidth: '90vw',
                }}
              >
                <div className="mr-4 p-1">
                  {new Intl.DateTimeFormat('en-GB', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  }).format(currentTime)}
                </div>
                <div className="mr-4 p-1">
                  {new Intl.DateTimeFormat('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(currentTime)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Shutdown Confirmation Dialog */}
      {showShutdownDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-gray-950 border border-indigo-950 text-white rounded-lg shadow-lg p-6 w-80">
            <h2 className="text-lg font-bold mb-4 text-green-500 text-glow-green">Shutdown System</h2>
            <p className="mb-6 text-sm text-green-500 text-glow-green">Are you sure you want to shut down?</p>
            <div className="flex justify-between">
              <button
                className="border border-green-500 hover:border-green-500/60 border-dashed text-green-500 px-4 py-2 rounded-md"
                onClick={() => {
                  setShuttingDown(true);
                  setShowShutdownDialog(false);
                }}
              >
                Yes
              </button>
              <button
                className="border border-green-500 hover:border-green-500/60 border-dashed  px-4 py-2 rounded-md text-green-500"
                onClick={() => setShowShutdownDialog(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Taskbar;
