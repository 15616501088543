import { FaXTwitter, FaTelegram } from 'react-icons/fa6';
import Draggable from 'react-draggable';
import { useWindowContext } from '../context/WindowContext';
import { XMarkIcon, MinusIcon } from '@heroicons/react/24/solid'

const Socials = () => {
  const {toggleSocial, isSocialVisible, socialRef } = useWindowContext();

  if (!isSocialVisible) return null;


  return (
    <Draggable nodeRef={socialRef} handle=".draggable-header" cancel=".btn">
      <div
        ref={socialRef}
        className={`border-indigo-600 border relative font-bold bg-black border-solid  rounded-b-xl m-5 w-11/12 max-w-lg cursor-move`}
      >
        <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-gray-600">
        <h1 className="text-xl text-white font-bold">social.exe</h1>
        <div className="flex space-x-2">
          <button className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() =>toggleSocial()}>
          <MinusIcon className='w-4 h-4'/>
          </button>
          <button className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => toggleSocial()}>
          <XMarkIcon className='w-4 h-4'/>
          </button>
        </div>
      </div>

          <div className="flex items-center justify-center space-x-6 mt-4 p-4">
            <a
              href={process.env.REACT_APP_X_URL}
              className="flex items-center text-white text-sm sm:text-md"
            >
              <FaXTwitter className="h-6 sm:h-8 mr-2" />
              X
            </a>
            <span className="hidden sm:inline text-transparent bg-clip-text text-md bg-gray-100/30">|</span>
            <a
              href={process.env.REACT_APP_TELEGRAM}
              className="flex items-center text-white text-sm sm:text-md"
            >
              <FaTelegram className='h-6 sm:h-8 mr-2'/>
              Telegram
            </a>
          </div>
      </div>
    </Draggable>
  );
};

export default Socials;
