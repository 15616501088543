
import { useCurrency } from '../context/CurrencyContext';
import { useSolPrice } from '../context/SolContext';

const SolUI: React.FC = () => {
  const { currencySymbol, convertPrice } = useCurrency();
  const { solPrice, loading } = useSolPrice();

  return (
    <div className="p-4 text-center text-white text-glow-blue">
      {loading ? (
        <p>Loading SOL price...</p>
      ) : (
        <p>
          Current SOL Price: <span className="font-bold">
            {currencySymbol}{convertPrice(solPrice)}
          </span>
        </p>
      )}
    </div>
  );
};

export default SolUI;