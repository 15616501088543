import { useState, useEffect, FormEvent } from "react";
import { collection, addDoc, query, orderBy, onSnapshot, Timestamp } from "firebase/firestore";
import { db } from "../data/firebase";
import { useWindowContext } from "../context/WindowContext";
import { XMarkIcon, MinusIcon } from "@heroicons/react/24/solid";
import Draggable from "react-draggable";
import { useWalletContext } from "../context/WalletContext";



const Chat: React.FC = () => {
  const { toggleChat, chatVisible, chatRef } = useWindowContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [userName, setUserName] = useState(""); // State for the user's name
  const [isUsernameOverlayVisible, setIsUsernameOverlayVisible] = useState(true); // State for overlay visibility
  const [lastMessageTime, setLastMessageTime] = useState<number | null>(null); // Track the last message time
  const {walletAddress} = useWalletContext();
  const devWallet = process.env.REACT_APP_DEV_WALLET

  useEffect(() => {
    // Fetch messages from Firestore
    const q = query(collection(db, "messages"), orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages: Message[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<Message, "id">),
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, []);

  useEffect(()=> {
    if(walletAddress) {
      if(walletAddress === devWallet) {
        setUserName('DEV');
      }else{
        let name = walletAddress.substring(0, 6) + '...' + walletAddress.substring(walletAddress.length - 4);
        setUserName(name);
      }
      setIsUsernameOverlayVisible(false);
    }
  },[walletAddress, devWallet])

  const sendMessage = async (e: FormEvent) => {
    e.preventDefault();

    const currentTime = Date.now();

    // Check if 5 seconds have passed since the last message was sent
    if (lastMessageTime && currentTime - lastMessageTime < 5000) {
      alert("Please wait 5 seconds before sending another message.");
      return;
    }

    if (newMessage.trim() && userName.trim()) {
      try {
        // Add new message to Firestore
        await addDoc(collection(db, "messages"), {
          text: newMessage,
          timestamp: Timestamp.now(),
          user: userName, // Store user name with message
        });

        // Update last message time
        setLastMessageTime(currentTime);

        setNewMessage(""); // Clear input field
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    } else {
      alert("Please enter your name and a message before sending.");
    }
  };


  const handleUsernameSubmit = (name: string) => {
    if (name.toUpperCase() !== 'DEV') {
      setUserName(name);
      if (userName.trim()) {
        setIsUsernameOverlayVisible(false); // Hide overlay if username is set
      } else {
        alert("Please enter a valid username.");
      }
    }else{
      alert('Name not allowed.');
    }
  };

  if (!chatVisible) return null;

  // Calculate if the user can send a message based on the 5-second cooldown
  const canSendMessage = lastMessageTime ? Date.now() - lastMessageTime >= 5000 : true;

  return (
    <>
      {/* Chat Box */}
      <Draggable nodeRef={chatRef} handle=".draggable-header" cancel=".btn">
        <div
          ref={chatRef}
          className="border-indigo-600 border relative font-bold bg-black border-solid rounded-b-xl m-5 max-w-lg"
        >
          {/* Header */}
          <div className="draggable-header flex items-center justify-between mb-4 bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 p-1 border-b border-gray-600 cursor-move">
            <h1 className="text-xl text-white font-bold">chat.exe</h1>
            <div className="flex space-x-2">
              {/* Minimize Button */}
              <button
                className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                onClick={toggleChat}
              >
                <MinusIcon className="w-4 h-4" />
              </button>
              {/* Close Button */}
              <button
                className="btn text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                onClick={toggleChat}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Chat Content */}
          <div className="relative p-8 text-white h-64 overflow-y-scroll gradient-scrollbar">
            {/* Overlay asking for username */}
            {isUsernameOverlayVisible && chatVisible && (
              <div className="absolute inset-0 bg-black flex items-center justify-center z-50">
                <div className="bg-black p-6 rounded-lg shadow-lg w-96">
                  <h2 className="text-xl mb-4">Enter Your Username</h2>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter your name"
                    className="w-full p-2 mb-4 border rounded text-black"
                  />
                  <button
                    onClick={() => handleUsernameSubmit(userName)}
                    className="bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 text-white p-2 rounded w-full"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            {!isUsernameOverlayVisible && (
              <div>
                {messages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-2 flex ${
                    message.user === userName ? "justify-end" : "justify-start"
                  }`}
                >
                  <p
                    className={`text-sm p-2 rounded ${
                      message.user === "DEV"
                        ? "bg-gradient-to-r from-green-500/20 via-green-300/20 to-green-200/20 text-green-500 text-glow-green font-bold"
                        : message.user === userName
                        ? "bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 text-white"
                        : "bg-gray-700 text-white"
                    }`}
                  >
                    <strong>{message.user}: </strong>
                    {message.text}
                  </p>
                </div>
              ))}

              </div>
            )}
          </div>

          {/* Input Field */}
          <form
            onSubmit={sendMessage}
            className="flex items-center justify-between p-4 border-t border-gray-600 bg-black rounded-xl"
          >
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-grow p-2 bg-gray-800 text-white rounded-l"
              disabled={!userName.trim()} // Disable if no username
            />
            <button
              type="submit"
              disabled={!userName.trim() || !canSendMessage} // Disable if no username or within 5 seconds of last message
              className={`bg-gradient-to-r from-blue-700 via-indigo-500 to-violet-500 hover:bg-blue-600 text-white px-4 py-2 rounded-r ${
                !userName.trim() || !canSendMessage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Send
            </button>
          </form>
        </div>
      </Draggable>
    </>
  );
};

export default Chat;
